@import '../../styles/styles';

.btn {
  background-color: transparent;
  background-image: url('/assets/icons/icon-caretDownBlue.svg');
  background-position: right;
  background-repeat: no-repeat;
  border: none;
  color: $light-sea-green;
  font-size: 16px;
  line-height: 18px;
  padding: 0 20px 0 0;

  @include mediaQuery(max, $mobile) {
    padding: 10px 30px 10px 10px;
    margin-left: -10px;
    background-position: 93% 50%;
  }
}

.btnActive {
  @extend .btn;
  background-image: url('/assets/icons/icon-caretUpBlue.svg');
}

// Destination page Read More
.btn:global(#destination_overview_see_more_btn) {
  background-image: url('/assets/icons/icon-chevronRightRed.svg');
  color: $capeCoral;
}

.btnActive:global(#destination_overview_see_more_btn) {
  background-image: url('/assets/icons/icon-chevronDownRed.svg');
  color: $capeCoral;
}
