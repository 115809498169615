@import 'styles/styles';

.header {
  align-self: flex-start;
}
.title {
}
.subtitle {
  margin-bottom: 1rem;
  font-size: 18px;
  margin-bottom: 1rem;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 600;
}
