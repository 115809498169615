@import 'styles/styles';
@import 'styles/helpers';
@import 'styles/select';

.hero {
  width: 100%;
  height: 100%;
  color: $white;
  //max-height: 40.625rem;
  position: relative;

  & > img {
    max-width: 100%;
    height: 100%;
  }
}

.heroContentContainer {
  position: relative;
  padding: 10rem 5rem 6rem;

  @include mediaQuery(max, $smDesktop) {
    padding: 8rem 3rem;
  }

  @include mediaQuery(max, $tablet) {
    padding: 6rem 3rem;
  }

  @include mediaQuery(max, $mobile) {
    height: auto;
    padding: 5rem 1rem 4rem;
  }
}

.heroContent {
  margin: 0 auto;
  max-width: $maxContentWidth;
  z-index: 5;

  @include mediaQuery(min, $smTablet) {
    margin-bottom: 4rem;
  }
}

.title {
  @extend .section-title;
  color: $white;
}

.subtitle {
  @extend .section-subtitle;
  color: $white;
  max-width: 38rem;
}

.viewLocation {
  margin-top: 2rem;
  text-align: right;

  @include mediaQuery(max, $tablet) {
    display: none;
  }

  a {
    color: $white;
    font-family: $font-primary;
    font-size: 0.875rem;
    font-weight: 700;
    text-decoration: none;
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  }

  svg {
    bottom: -7px;
    padding-left: 4px;
    position: relative;
    width: 19px;
  }
}

.mask {
  position: absolute;
  bottom: 0;
  left: 0;
  max-width: 100%;
  overflow: hidden;
  pointer-events: none;
  right: 0;

  svg {
    height: auto;
    max-width: calc(100% + 1rem);
    position: relative;
    top: 0.5rem;
    width: calc(100% + 1rem);
  }
}
