@import 'styles/styles';

.section {
  padding: 0 5rem 5rem 5rem;

  @include mediaQuery(max, 1085px) {
    padding-bottom: 0 3rem 3rem 3rem;
  }
  @include mediaQuery(max, $mobile) {
    padding: 3rem 1rem;
  }
}

.content {
  margin: 0 auto;
  max-width: 762px;
  display: flex;
  flex-direction: column;
}

.button {
  margin: 40px auto 0;
}

.cardGrid {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  margin: 40px auto 0;
  max-width: 1105px;
  width: 100%;

  @include mediaQuery(max, 1140px) {
    padding: 0 16px;
  }

  @include mediaQuery(max, 1110px) {
    max-width: 750px;
  }

  @include mediaQuery(max, $tablet) {
    max-width: 602px;
    padding: 0;
  }

  @include mediaQuery(max, 775px) {
    margin: 30px auto 0;
  }

  @include mediaQuery(max, 730px) {
    max-width: 100%;
  }

  @include mediaQuery(max, 705px) {
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }
}
