@import 'styles/styles';

section.destinationsSection {
  align-items: flex-start;
  width: 100%;
}

.content {
  width: 100%;
}

.facetsContainer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: flex-start;
  flex-wrap: wrap;
}

.destination {
  display: flex;
  flex-direction: column;
  flex: 0 0 30%;

  &.marginBottom {
    margin-bottom: 3rem;
    @include mediaQuery(max, $mobile) {
      margin-bottom: 1.5rem;
    }
  }

  @include mediaQuery(max, $mobile) {
    flex: 0 0 46%;
  }
}

.propertyLabel {
  background-color: $dark-cerulean;
  border-radius: 3px;
  color: $white;
  display: inline-block;
  font-family: $font-secondary;
  font-size: 14px;
  font-weight: 800;
  letter-spacing: 1px;
  line-height: 12px;
  margin-bottom: 0px;
  padding: 5px 8px 5px;
  text-align: center;
  text-transform: uppercase;
  margin-top: 0.5rem;
}

.displayText {
  margin-top: 0.5rem;
  font-weight: 600;
  font-size: 16px;
}

.image {
  display: block;
  position: relative;

  min-width: 250px;
  min-height: 250px;

  @include mediaQuery(max, $tablet) {
    min-width: 150px;
    min-height: 150px;
  }

  span {
    border-radius: 4px;
  }
}

.link {
  // color: $saldova;
}
