@import '../../styles/styles';

.descriptiveCard {
  background-color: $white;
  border-bottom-style: solid;
  border-bottom-width: 6px;
  border-radius: 4px;
  box-shadow: 0 10px 40px rgba(10, 63, 70, 0.14);
  max-width: 344px;
  padding: 2rem;
  width: 100%;

  &:nth-child(-n + 3) {
    margin-bottom: 26px;
  }

  &:first-child {
    border-bottom-color: $yellow;
  }

  &:nth-child(2) {
    border-bottom-color: $light-sea-green;
  }

  &:nth-child(3) {
    border-bottom-color: $dark-orange;
  }

  &:nth-child(4) {
    border-bottom-color: $bittersweet;
  }

  &:nth-child(5) {
    border-bottom-color: $dark-cerulean;
  }

  &:last-child {
    border-bottom-color: $cyprus;
  }

  @include mediaQuery(max, 1110px) {
    margin-bottom: 26px;
  }

  @include mediaQuery(max, $tablet) {
    max-width: 289px;
    padding: 28px 20px;
  }

  @include mediaQuery(max, $mobile) {
    margin-bottom: 12px;
    max-width: 100%;
    padding: 28px 15px;

    &:nth-child(-n + 3) {
      margin-bottom: 12px;
    }
  }

  .descriptiveCardDescription {
    color: $casal;
    font-size: 15px;
    line-height: 21px;
    margin: 0;
    text-align: center;

    & > p {
      @extend .descriptiveCardDescription;
    }
  }
}

.descriptiveCardTitle {
  display: block;
  font-size: 21px;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 5px;
  text-align: center;

  @include mediaQuery(max, $tablet) {
    font-size: 18px;
    line-height: 21px;
  }

  @include mediaQuery(max, $mobile) {
    display: block;
  }
}
