@import '../../styles/styles';

.difference {
  align-items: center;
  display: flex;
  flex: 0 0 200px;
  justify-content: center;

  &:first-child {
    .differenceIcon {
      background-color: rgba($portOrange, 0.15);
    }

    .differenceIconWhite {
      background-color: $dark-green-opaque;
    }
  }

  &:last-child {
    .differenceIcon {
      background-color: rgba($dark-green, 0.15);
    }

    .differenceIconWhite {
      background-color: $light-sea-green-opaque;
    }
  }

  @include mediaQuery(min, $smDesktop) {
    padding: 0 0.5rem;
    max-width: 320px;
  }

  @include mediaQuery(max, 1120px) {
    flex: 0 0 30%;
    justify-content: space-between;
  }

  @include mediaQuery(max, 950px) {
    margin-bottom: 10px;
  }

  @include mediaQuery(min, $smTablet) {
    display: block;
  }
}

.differenceLg {
  @extend .difference;
  flex: 0 0 278px;
  flex-direction: column;
  text-align: center;

  @include mediaQuery(max, 955px) {
    flex: 0 0 30%;
    margin: 10px auto;
  }
}

.differenceIcon {
  align-items: center;
  background-color: rgba($evolveBlue, 0.15);
  border-radius: 50%;
  display: flex;
  height: 85px;
  margin-bottom: 30px;
  position: relative;
  width: 85px;

  img {
    height: 54px;
    position: absolute;
    right: -10px;
    width: 54px;
  }

  @include mediaQuery(max, $smTablet) {
    height: 70px;
    margin-right: 35px;
    width: 70px;

    img {
      height: 50px;
      width: 50px;
    }
  }
}

.differenceIconWhite {
  @extend .differenceIcon;
  background-color: $dark-orange-opaque;
}

.differenceIconLg {
  @extend .differenceIcon;

  img {
    height: auto;
  }

  @include mediaQuery(max, 955px) {
    height: 58px;
    margin-right: 25px;
    width: 58px;

    img {
      width: 34px;
    }
  }
}

.differenceContent {
  @include mediaQuery(max, 1120px) {
    flex: 0 1 200px;
    max-width: 200px;
  }

  @include mediaQuery(max, $smTablet) {
    flex: none;
  }
}

.differenceLabel {
  margin: 0px;
  font-size: 20px;
  font-weight: 600;
  color: $blue-dark;
  @include mediaQuery(max, $smTablet) {
    display: block;
  }
}

span.differenceDescription > p {
  color: $casal;
  font-size: 15px;
  font-weight: 300;
  line-height: 24px;
  margin: 0;

  @include mediaQuery(max, 950px) {
    line-height: 19px;
    margin-top: 5px;
  }

  & > p {
    @extend .differenceDescription;
  }
}

.differenceLabelWhite,
.differenceDescriptionWhite {
  color: $white;

  & > p {
    color: $white;
  }
}

.listItem {
  color: $casal;
  font-size: 15px;
  font-weight: 600;
  line-height: 24px;
}

//Evolve Difference Styles for Wrapping in Responsive Layout
.responsiveLayout,
.homepage {
  .difference {
    @include mediaQuery(min, $smDesktop) {
      justify-content: flex-start;
      max-width: none;
    }

    @include mediaQuery(max, 950px) {
      margin-bottom: 24px;
    }
  }
  .differenceIcon {
    @include mediaQuery(max, $smTablet) {
      flex: 0 0 auto;
    }
  }
  .differenceContent {
    @include mediaQuery(max, $smTablet) {
      max-width: none;
      flex: 1 1 auto;
    }
  }
}
