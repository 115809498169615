@import 'styles/styles';

.section {
  padding: 5rem;

  @include mediaQuery(max, 1085px) {
    padding: 3rem;
  }
  @include mediaQuery(max, $mobile) {
    padding: 3rem 1rem;
  }
}

.content {
  margin: 0 auto;
  max-width: $maxContentWidth;

  @include mediaQuery(max, $mdDesktop) {
    max-width: 915px;
  }

  @include mediaQuery(max, 1023px) {
    max-width: 100%;
    padding: 3rem 0 1rem;
  }
}
.overview {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  img {
    padding-top: 1rem;
    max-width: 100%;

    @include mediaQuery(max, 1023px) {
      margin: 0 1rem 0 3rem;
    }

    @include mediaQuery(max, $mobile) {
      margin: 0 1rem;
    }
  }
  @include mediaQuery(min, $lgTablet) {
    justify-content: space-between;
    align-items: center;
    margin-right: 20px;

    img {
      max-width: 35%;
    }
  }
}

.overviewImage {
  order: 2;
  @include mediaQuery(max, 1023px) {
    order: 3;
  }
}

.overviewHeader {
  order: 1;

  @include mediaQuery(min, $lgTablet) {
    max-width: 50%;
  }

  @include mediaQuery(max, 1023px) {
    margin: 0 3rem;
  }

  @include mediaQuery(max, $mobile) {
    margin: 0 1rem;
  }
}

.readMore {
  margin: 3rem 0 0;
  order: 3;

  @media (min-width: $lgTablet) and (max-width: 1023px) {
    max-width: 50%;
  }

  @include mediaQuery(max, 1023px) {
    margin: 0 3rem;
    order: 2;
  }

  @include mediaQuery(max, $mobile) {
    margin: 0 1rem;
  }
}
