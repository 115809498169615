@import 'styles/styles';
@import 'styles/helpers';
@import 'styles/select';

section.heroSection {
  padding-top: 0rem !important;
  padding-bottom: 0rem !important;
}

.hero {
  width: 100vw;
  height: 100%;
  color: $white;
  position: relative;
  padding-top: 5rem;
  padding-bottom: 5rem;

  & > img {
    max-width: 100%;
    height: 100%;
  }
}

.heroContentContainer {
  position: relative;
  padding-right: 24px;
  padding-left: 24px;
  width: 100%;

  // Tablet
  @media screen and (min-width: 600px) {
    padding-right: 48px;
    padding-left: 48px;
  }

  // Desktop
  @media screen and (min-width: 1196px) {
    @include desktopGutters;
  }
}

.heroContent {
  color: $saldova;
  background-color: $white;
  padding: 1.5rem 0;
  max-width: 100%;
  z-index: 5;
  position: absolute;
  top: 100%;
  left: 0;
  border-radius: 4px;
  padding-bottom: 0px;
  position: inherit;

  @include mediaQuery(min, $tablet) {
    position: relative;
    max-width: 400px;
    padding: 2rem 1.5rem;
  }

  @include mediaQuery(min, $desktop) {
    position: relative;
    max-width: 575px;
  }
}

.title {
  @extend .section-title;
  color: $saldova;
  font-size: 44px;
  margin-top: 0.75rem;
}

.subtitle {
  @extend .section-subtitle;
  color: $saldova;
  max-width: 38rem;
  font-size: 18px;
}

.viewLocation {
  margin-top: 2rem;
  text-align: right;

  @include mediaQuery(max, $tablet) {
    display: none;
  }

  a {
    color: $white;
    font-family: $font-primary;
    font-size: 0.875rem;
    font-weight: 700;
    text-decoration: none;
    text-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
  }

  svg {
    bottom: -7px;
    padding-left: 4px;
    position: relative;
    width: 19px;
  }
}

.labelRow {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  justify-content: space-between;
  align-items: center;

  span {
    font-size: 14px;
    margin-right: 10px;
  }
}

// ULTRA HACK: Everything below here is to force SearchBar to always be in mobile layout
.autoPageSearchBar {
  padding: 0px;
  background-color: $white;
  display: flex;
  flex-wrap: wrap;

  align-items: center;
  border-radius: 4px;
  flex-direction: row;
  min-width: 15rem;

  > div {
    width: 100%;
    flex-grow: 1;
    margin-left: 0px;
    margin-right: 0px;

    &:last-child {
      margin-bottom: 0px;
    }

    &:not(:last-child) {
      margin-bottom: 0.5rem;
    }

    > div {
      max-width: 100%;
      width: 100%;
    }
  }

  :global(#DateRangePickerContainer_wrapper_div) {
    width: 100%;
    max-width: 100%;

    :global(#DateRangePickerInput_wrapper_div) {
      align-items: center;
      display: flex;
      flex-direction: row;
      max-width: 100%;
      width: 100%;

      :global(.DateInput) {
        width: 100%;
        max-width: 100%;
      }
    }
  }
}
