@import '../../styles/styles';

.sectionLabelCentered {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: 0 auto;
  max-width: 615px;
  text-align: center;
  width: 100%;

  @include mediaQuery(max, 1120px) {
    flex: none;
    max-width: 915px;
    width: 100%;
  }

  @include mediaQuery(max, $tablet) {
    max-width: 615px;
  }

  @include mediaQuery(max, 685px) {
    max-width: 425px;
  }

  @include mediaQuery(max, 500px) {
    max-width: 310px;
  }
}

.centeredTitle {
  margin-bottom: 17px;
}

.centeredTitleSm {
  max-width: 615px;
  width: 100%;
}

.whiteText {
  color: $white;
}
