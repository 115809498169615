@import 'styles/styles';

.section {
  padding: 0 5rem 5rem 5rem;

  @include mediaQuery(max, 1085px) {
    padding: 0 3rem 3rem 3rem;
  }
  @include mediaQuery(max, $mobile) {
    padding: 3rem 1rem;
  }
}

.content {
  margin: 0 auto;
  max-width: 762px;
  display: flex;
  flex-direction: column;
}

.faq {
  background-color: transparent;
  border: none;
  display: block;
  font-size: 18px;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: left;
  min-width: 100%;
}

.faqList {
  margin: 60px auto 40px;
  max-width: 1095px;
  width: 100%;

  @include mediaQuery(max, $tablet) {
    margin: 30px auto;
  }

  @include mediaQuery(max, $mobile) {
    margin: 0 auto;
  }
}

.questionWrapper {
  background-position: 5px;
  background-repeat: no-repeat;
  border-bottom: 1px solid $grey-very-light;
  padding: 26px 44px;
  transition: 500ms all;
  z-index: 1;
  position: relative;

  &:hover {
    cursor: pointer;
  }

  span {
    font-size: 18px;
    font-weight: 600;
    line-height: 21px;
  }
}

.questionWrapper,
.questionWrapperActive {
  svg {
    position: absolute;
    left: 5px;
    top: 50%;
    transform: translateY(-50%);
  }
}

.questionWrapperActive {
  @extend .questionWrapper;
  background-image: url('/assets/icons/cross.svg');
}

.answerWrapper {
  height: 0;
  margin: -10px 0 0;
  max-height: 0;
  opacity: 0;
  padding: 0;
  transform: translateY(0);
  transition: 500ms all;
  visibility: hidden;
}

.answerWrapperActive {
  height: auto;
  line-height: 1.5;
  margin: 0;
  max-height: 35rem;
  opacity: 1;
  padding: 13px 40px 0;
  transition: 500ms all;
  visibility: visible;

  @include mediaQuery(max, $tablet) {
    padding: 13px 16px 0;
    max-height: 100rem;
  }
}

.btn {
  margin: 0 auto 90px;
  text-align: center;

  @include mediaQuery(max, $mobile) {
    margin: 0 auto 30px;
    width: 100%;
  }
}

// From RegionalLinks styles
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
.fadeIn {
  animation: fadeIn ease 0.3s;
}

.linksWrapper {
  display: flex;
  transition: all 0.3s ease;

  &.isOpen {
    margin-top: 1rem;
  }
}

.item {
  padding: calc(1rem - 3px) 0;
  border-top: 2px solid $divider;

  h3 {
    @include noWebkitHilight;

    cursor: pointer;
    font-family: $font-secondary;
    font-size: 22px;
    font-weight: 800;
    line-height: 1.18;
    color: $saldova;
    padding: 3px 0;
    outline-offset: -1px;
    outline-width: thin;
  }
}

.links {
  display: none;
  margin-left: 23px;

  &.isOpen {
    display: block;
    @extend .fadeIn;
  }

  li {
    list-style: none;

    &:not(:last-of-type) {
      margin-bottom: 0.5rem;

      @include mediaQuery(max, $mobile) {
        margin-bottom: calc(0.5rem + 15px);
      }
    }

    a {
      text-decoration: none;
      color: $blue-extra-dark;
      font-size: 16px;
      font-weight: 500;

      &:hover {
        text-decoration: underline;
      }
    }
  }
}

.icon {
  margin-right: 10px;
}
