@import '../../styles/styles';

.imageGallery {
  display: flex;
  height: 445px;
  justify-content: space-between;
  margin-bottom: 28px;
  margin-top: 80px;

  @include mediaQuery(max, 1000px) {
    height: 206px;
    margin-top: 120px;
  }
}

.resultDetails {
  display: flex;
  justify-content: space-between;
  padding: 0 11%;

  @include mediaQuery(max, 1200px) {
    padding: 0 6%;
  }

  @include mediaQuery(max, $lgTablet) {
    padding: 0 2rem;
  }

  @include mediaQuery(max, $mobile) {
    padding: 0 1rem;
  }
}

.details {
  flex: 0 0 55%;

  @include mediaQuery(max, 1100px) {
    flex: 0 0 50%;
  }

  @include mediaQuery(max, $lgTablet) {
    flex: 0 0 100%;
  }
}

.detail {
  border-bottom: 1px solid $divider;
  margin: 0 auto;
  max-width: 665px;
  padding: 32px 0;

  @include mediaQuery(max, $lgTablet) {
    max-width: 100%;
  }
}

.detailTop {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.detailTopRight {
  display: flex;
  justify-content: space-between;
  flex: 0 0 192px;
}

.detailBottom {
  display: flex;
  justify-content: space-between;
}

.detailBottomLeft {
  width: calc(100% - 100px);
}

.booking {
  box-shadow: $cardBoxShadow;
  flex: 0 0 360px;
  height: 380px;

  @include mediaQuery(max, $lgTablet) {
    display: none;
  }
}

.bookingTop {
  background-color: $glacier;
  padding: 36px 28px;
}

.bookingBottom {
  padding: 25px 28px 36px;
}
