@import '../../styles/styles';

.section {
  background-color: $white;
  padding: 5rem;

  @include mediaQuery(max, 1085px) {
    padding: 3rem;
  }

  @include mediaQuery(max, 1023px) {
    padding: 3rem 3rem 0;
  }

  @include mediaQuery(max, $mobile) {
    padding: 3rem 1rem 0;
  }
}

.sectionLLP {
  background-color: rgba($glacier, 0.25);
}

.small {
  padding: 3rem 0 0;
  max-width: 100%;
}

.content {
  align-items: center;
  display: flex;
  justify-content: space-between;
  margin: 0 auto;
  max-width: $maxContentWidth;

  @include mediaQuery(max, 1120px) {
    flex-direction: column;
  }

  @include mediaQuery(max, 1024px) {
    margin-left: 0;
  }

  .small & {
    flex-direction: column;
  }
}

.benefits {
  flex: 0 0 360px;
  margin-right: 15px;

  @include mediaQuery(max, 1120px) {
    flex: none;
    margin-right: 0;
    max-width: 915px;
    width: 100%;
  }

  .small & {
    flex: none;
    margin-right: 0;
    max-width: 915px;
    width: 100%;
  }
}

.differenceGrid {
  display: flex;
  flex: 0 0 630px;
  flex-wrap: wrap;
  margin: 1rem 0 2rem;
  justify-content: space-between;

  @include mediaQuery(min, $smTablet) {
    margin-top: 2rem;
  }
  @include mediaQuery(min, 1120px) {
    margin-top: 0;
  }
  @include mediaQuery(max, 1120px) {
    flex: none;
    width: 100%;
  }
  @include mediaQuery(max, 767px) {
    display: block;
  }
  .small & {
    flex: none;
  }
}

//Evolve Difference Styles for Wrapping in Responsive Layout
.responsiveLayout,
.homepage {
  .content {
    max-width: none;
  }
  .benefits {
    @include mediaQuery(max, 1120px) {
      max-width: none;
    }
  }
  .differenceGrid {
    flex-wrap: nowrap;
  }
}

//evolve difference styles specific to homepage
.homepage {
  align-items: center;
  display: flex;
  flex-direction: column;
  width: 100%;
  padding-left: 18px;
  padding-right: 18px;

  @media screen and (min-width: 601px) {
    padding-left: 54px;
    padding-right: 54px;
  }
  @media screen and (min-width: 901px) {
    @include tabletGutters;
  }
  @media screen and (min-width: 1086px) {
    padding-left: 80px;
    padding-right: 80px;
  }
  @media screen and (min-width: 1254px) {
    @include desktopGutters;
  }
}

/* TODO: Delete me when DD-404 is redefined */
.evolveDifference {
  border: 1px #d8d8d8 solid;
  border-radius: 4px;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 24px;
  padding: 18px 24px;
}

.evolveDifferenceBenefits {
  flex: 0 0 220px;
  margin-right: 16px;
}

.evolveDifferenceTitle {
  color: #1ab2c5;
  margin: 0.5rem 0 1rem;
  font-size: 0.875rem;
  font-family: Nunito, sans-serif;
  font-weight: 700;
  letter-spacing: 2px;
}

h2.evolveDifferenceSubtitle {
  font-size: 1.25rem;
  line-height: 1.625rem;
  margin: 12px 0 8px;
  color: #093f46;
  display: block;
  font-family: Nunito, sans-serif;
  letter-spacing: -0.38px;
  font-weight: 800;
  padding: 0;
}

.evolveDifferenceDescription {
  font-size: 0.875rem;
  font-weight: 300;
  color: #093f45;
  display: block;
  font-family: Montserrat, sans-serif;
  line-height: 1.375rem;
  margin-bottom: 1rem;
}

.evolveDifferenceGrid {
  display: flex;
  flex-direction: row;
  margin: 0.75rem 0 1rem;
}

.evolveDifferenceItem {
  padding: 0 0 0 1rem;
  flex: 1 1 33%;
}

.evolveDifferenceIcon {
  align-items: center;
  border-radius: 50%;
  display: flex;
  height: 60px;
  margin-bottom: 30px;
  position: relative;
  width: 60px;
}

.evolveDifferenceIcon.rate {
  background-color: rgba(249, 161, 30, 0.15);
}

.evolveDifferenceIcon.rest {
  background-color: rgba(26, 178, 197, 0.15);
}

.evolveDifferenceIcon.back {
  background-color: rgba(192, 210, 15, 0.15);
}

.evolveDifferenceIcon img {
  height: 40px;
  position: absolute;
  right: -8px;
  width: 40px;
}

.evolveDifferenceItemLabel {
  font-size: 16px;
  line-height: 27px;
  margin: 0;
  font-weight: 600;
  color: #0e3f44;
}

.evolveDifferenceItemDescription {
  color: #3e5a5e;
  font-size: 0.75rem;
  font-weight: 300;
  line-height: 1.25rem;
}

@media screen and (max-width: 1000px) {
  .evolveDifference {
    display: none;
  }
}

@media screen and (max-width: 1174px) {
  .evolveDifference {
    flex-direction: column;
  }
  .evolveDifferenceBenefits {
    flex: 1 1 auto;
  }
  .evolveDifferenceItem {
    padding: 0;
    margin: 0 0 0 1rem;
    flex: 1 1 calc(33% - 0.66rem);
  }
  .evolveDifferenceItem:first-of-type {
    margin: 0;
  }
  .evolveDifferenceIcon {
    margin-bottom: 10px;
  }
}
