@import 'styles/styles';

.section {
  padding: 5rem;

  @include mediaQuery(max, 1085px) {
    padding: 3rem;
  }
  @include mediaQuery(max, $mobile) {
    padding: 3rem 1rem;
  }
}

.content {
  margin: 0 auto;
  max-width: $maxContentWidth;

  @include mediaQuery(max, $mdDesktop) {
    max-width: 915px;
  }

  @include mediaQuery(max, 1023px) {
    max-width: 100%;
    padding: 3rem 0 1rem;
  }
}

.header {
  margin-bottom: 20px;
}
