@import 'styles/styles';

ul.breadcrumb {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  padding: 10px 0px;
  list-style: none;
  background-color: white;
}

ul.breadcrumb li {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 16px;
  font-weight: 600;
  line-height: 24px;
  color: $headings;
}

ul.breadcrumb li:after {
  content: '';
  display: block;
  background: url('/assets/icons/icon-caretBlue.svg') center center no-repeat;
  background-size: 8px 12px;
  padding: 5px;
  width: 8px;
  height: 12px;
}

ul.breadcrumb li:last-child {
  &::after {
    background: none;
  }
}

ul.breadcrumb li p {
  line-height: 24px;
  margin: 0px;
}

ul.breadcrumb li a {
  color: $viridianGreen;
  text-decoration: none;
}

ul.breadcrumb li a:hover {
  color: $headings;
  text-decoration: underline;
}
