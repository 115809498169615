@import 'styles/styles';

.faq {
  background-color: transparent;
  border: none;
  display: block;
  font-size: 18px;
  margin: 0;
  padding: 0;
  position: relative;
  text-align: left;
  min-width: 100%;
}

.faqGrid {
  margin-top: 0.15rem;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
}

.faqCol {
  width: 100%;

  &:first-of-type {
    margin-bottom: 2rem;
    @include mediaQuery(min, $tablet) {
      margin-bottom: 0rem;
    }
  }

  @include mediaQuery(min, $tablet) {
    width: calc(50% - 3rem);
  }
}

.faqItem {
  margin-bottom: 2rem;

  &:last-of-type {
    margin-bottom: 0rem;
  }

  h5 {
    font-size: 32px;
    margin-bottom: 1rem;
    font-family: $font-secondary;
    font-weight: 800;
  }

  h3 {
    font-family: $font-secondary;
    font-size: 22px;
    font-weight: 800;
    line-height: 1.18;
    color: $saldova;
    margin-bottom: 0.5rem;
  }
}

.answerWrapper {
  display: flex;
  font-size: 16px;
  line-height: 1.69;

  a {
    font-weight: 700;
  }
}

.buttonWrapper {
  display: block;
  margin-top: 0.5rem;
}
