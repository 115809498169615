@import 'styles/styles';

.main {
  section:nth-child(2) {
    background-image: $linearGradientFullReverse;
  }
  section:last-child {
    background-image: $linearGradientFull;
  }

  &.topPadSection {
    section:nth-child(2) {
      padding-top: 5rem;
    }
  }
}
